<template>
  <div>
    <img v-if="logoURL" :src="logoURL" alt="logo" class="logo" />
    <div v-else></div>
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
} from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import store from "@/store";
export default defineComponent({
  setup() {
    const { skin } = useAppConfig();
    const logoURL = computed(() => {
      if (skin.value === "dark")
        return store.getters["settings/companyLogoDarkURL"];
      else return store.getters["settings/companyLogoURL"];
    });
    onMounted(async () => {
      await Promise.all([
        store.dispatch("settings/fetchCompanyLogo"),
        store.dispatch("settings/fetchCompanyLogoDark"),
      ]);
    });
    return {
      logoURL,
    };
  },
});
</script>

<style scoped></style>
